import ApplicationLogo from '@/Components/ApplicationLogo';
import { Head, Link } from '@inertiajs/react';

export default function Guest({ children }) {
    return (
        <>
            <Head>
                <meta name="robots" content="noindex, nofollow" />
            </Head>
            <div className="min-h-screen flex sm:justify-center items-center py-20 lg:py-0 lg:bg-white bg-darker-blue">
                <div className="lg:w-1/2 w-full flex justify-end">
                    <div className="2xl:w-full 2xl:max-w-xl w-11/12 2xl:mr-20 mx-auto">
                        <div className="lg:hidden block lg:mb-8 mb-20">
                            <Link href="/">
                                <ApplicationLogo className="w-auto h-[80px] mx-auto fill-current text-white text-20" />
                            </Link>
                        </div>
                        {children}
                    </div>
                </div>
                <div className="lg:w-1/2 lg:flex hidden bg-dark-blue h-full min-h-screen justify-center items-center">
                    <div>
                        <Link href="/">
                            <ApplicationLogo className="w-full sm:max-w-md h-auto fill-current text-black text-20" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
